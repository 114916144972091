import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import Conditions from '../components/conditions/Conditions'

import IndexLayout from '../layouts'
import HeaderDesktop from "../components/HeaderDesktop";
import Helmet from "react-helmet";

const PageTwo = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Helmet

      title={'AGB'}
      meta={[
        { name: 'description', content: 'Vilhelm7 Allgemeine Geschäftsbedingungen.' },
        { name: 'keywords', content: 'Vilhelm7, V7, agb, buchen, Hotel, Zimmer, Berlin, Übernachtung'},
        {name: 'page-type', content: 'Hotel, Apartments'},
        { name:'copyright', content:"https://www.vilhelm7.de"},
        { name:'X-UA-Compatible', content:"IE=edge"},


      ]}

    />
    <HeaderDesktop/>
    <Conditions></Conditions>

  </IndexLayout>
)

export default PageTwo
