import * as React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';


const StyledDiv = styled.div`

  width: 60%;
text-align:left;
margin:auto;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
padding-top:1%;
font-family: "Theano Didot", Regular;
font-size: 1.22em;
letter-spacing: 1px;

@media screen and (max-width: 1200px) {
   width:80%;
   }



`;

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 180%;
text-align: right;
line-height: 40px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:25%;
height:100%;



`;

const Einzug = styled.p`
font-family: "Theano Didot", Regular;
line-height: 1.5em;
color:rgb(68, 68, 68);
text-indent: 20px;
color:rgb(68, 68, 68);
height:100%;
`;

const P = styled.p`
font-family: "Theano Didot", Regular;
line-height: 1.5em;
color:rgb(68, 68, 68);
color:rgb(68, 68, 68);
margin-top:1.1%;
margin-left:20px;

`;

const PBold = styled.p`
font-family: "Theano Didot", Regular;
font-weight:bold;
line-height: 1.5em;
color:rgb(68, 68, 68);

color:rgb(68, 68, 68);
`;

const PItalic = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
font-style:italic;
line-height: 1.5em;
color:rgb(68, 68, 68);
width:30%;
color:rgb(68, 68, 68);
height:100%;
margin-top:1.4%;
text-indent: 20px;

`;

const Div = styled.div`
border-bottom: 2px solid rgba(68, 68, 68,.5);
margin-top:40px;
`




interface MidDivProps {
  className?: string
}

const MidDiv: React.FC<MidDivProps> = ({ }) => (
  <StyledDiv>
    <br/><br/>

     <PBold>I. Geltungsbereich</PBold>
    <P>1. Diese Geschäftsbedingungen gelten für Verträge über die mietweise Überlassung von Hotelzimmern zur Beherbergung, sowie alle für den Kunden erbrachten weiteren Leistungen
      und Lieferungen der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH).</P>
    <P>2. Die Unter- oder Weitervermietung der überlassenen Zimmer sowie deren Nutzung zu anderen als Beherbergungszwecken bedürfen der vorherigen schriftlichen Zustimmung der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH),
      wobei § 540 Abs. 1 Satz 2 BGB abbedungen wird, soweit der Kunde nicht Verbraucher ist.</P>
    <P>3. Geschäftsbedingungen des Kunden finden nur Anwendung, wenn dies vorher ausdrücklich schriftlich vereinbart wurde. </P>
<Div></Div>
    <br/><br/>
    <PBold>II. Vertragsabschluss, -partner; Verjährung</PBold>
    <P>1. Der Vertrag kommt durch die Annahme des Antrags des Kunden durch die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) zustande. Der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) steht es frei,
      die Zimmerbuchung schriftlich zu bestätigen.</P>
    <P> 2. Vertragspartner sind die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) und der Kunde. Hat ein Dritter für den Kunden bestellt, haftet er der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) gegenüber zusammen mit dem Kunden als Gesamtschuldner für alle Verpflichtungen aus dem Aufnahmevertrag V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH), sofern der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) eine entsprechende Erklärung des Dritten vorliegt. Alle Ansprüche gegen die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) verjähren grundsätzlich in einem Jahr ab dem Beginn der kenntnisabhängigen regelmäßigen Verjährungsfrist des § 199 Abs. 1 BGB. Schadensersatzansprüche verjähren kenntnisunabhängig in fünf Jahren.
      Die Verjährungsverkürzungen gelten nicht bei Ansprüchen, die auf einer vorsätzlichen oder
      grob fahrlässigen Pflichtverletzung der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) beruhen.</P>
    <Div></Div>
<br/><br/>
    <PBold>III. Leistungen, Preise, Zahlung, Aufrechnung</PBold>
    <P>1. Die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) ist verpflichtet, die vom Kunden gebuchten
      Zimmer bereitzuhalten und die vereinbarten Leistungen zu erbringen. </P>
    <P> 2. Der Kunde ist verpflichtet, die für die Zimmerüberlassung und die von ihm in Anspruch genommenen weiteren Leistungen geltenden bzw. vereinbarten Preise der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) zu zahlen. Dies gilt auch für vom Kunden veranlasste Leistungen und
      Auslagen der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) an Dritte.</P>
    <P>3. Die vereinbarten Preise schließen die jeweilige gesetzliche Mehrwertsteuer ein. Überschreitet der Zeitraum zwischen Vertragsabschluss und Vertragserfüllung vier Monate und erhöht sich der vom Hotel allgemein für derartige Leistungen berechnete Preis,
      so kann dieses den vertraglich vereinbarten Preis angemessen, höchstens jedoch um 5% anheben.</P>

    <P>4. Die Preise können vom Hotel ferner geändert werden, wenn der Kunde nachträglich Änderungen der Anzahl der gebuchten Zimmer, der Leistung der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) oder der Aufenthaltsdauer der Gäste wünscht und
      die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) dem zustimmt.</P>
    <P>5. Rechnungen der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) ohne Fälligkeitsdatum sind binnen 10 Tagen ab Zugang der Rechnung ohne Abzug zahlbar. Die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) ist berechtigt, aufgelaufene Forderungen jederzeit fällig zu stellen und unverzügliche Zahlung zu verlangen. Bei Zahlungsverzug ist die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) berechtigt, die jeweils geltenden gesetzlichen Verzugszinsen in Höhe von derzeit 8% bzw. bei Rechtsgeschäften, an denen ein Verbraucher beteiligt ist, in Höhe von 5% über dem Basiszinssatz zu verlangen.
      Der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH)
      bleibt der Nachweis eines höheren Schadens vorbehalten.</P>
    <P>6. Die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) ist berechtigt, bei Vertragsschluss oder danach, unter Berücksichtigung der rechtlichen Bestimmungen für Pauschalreisen, eine angemessene Vorauszahlung oder Sicherheitsleistung zu verlangen.
      Die Höhe der Vorauszahlung und die Zahlungstermine können im Vertrag schriftlich vereinbart werden.</P>
    <P> 7. Der Kunde kann nur mit einer unstreitigen oder rechtskräftigen Forderung gegenüber einer Forderung der V7 Berlin Residences
      (gesetzl. vertr. durch die 2inSeven GmbH) aufrechnen oder mindern.</P>
    <Div></Div>
    <br/><br/>
    <PBold> IV. Rücktritt des Kunden (i. e. Abbestellung, Stornierung) </PBold>
    <PBold> Nichtinanspruchnahme der Leistungen der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH)</PBold>
<P>1. Ein Rücktritt des Kunden von dem mit der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) geschlossenen Vertrag bedarf der schriftlichen Zustimmung der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH). Erfolgt diese nicht, so ist der vereinbarte Preis aus dem Vertrag auch dann zu zahlen, wenn der Kunde vertragliche Leistungen nicht in Anspruch nimmt. Dies gilt nicht bei Verletzung der Verpflichtung der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) zur Rücksichtnahme auf Rechte, Rechtsgüter und Interessen des Kunden, wenn diesem dadurch ein Festhalten
  am Vertrag nicht mehr zuzumuten ist oder ein sonstiges gesetzliches oder vertragliches Rücktrittsrecht zusteht.</P>
    <P> 2. Sofern zwischen der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) und dem Kunden ein Termin zum kostenfreien Rücktritt vom Vertrag schriftlich vereinbart wurde, kann der Kunde bis dahin vom Vertrag zurücktreten, ohne Zahlungs- oder Schadensersatzansprüche der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) auszulösen. Das Rücktrittsrecht des Kunden erlischt, wenn er nicht bis zum vereinbarten Termin sein Recht zum Rücktritt schriftlich gegenüber der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) ausübt,
      sofern nicht ein Fall des Rücktritts des Kunden gemäß Nummer 1 Satz 3 vorliegt.</P>
    <P>3. Bei vom Kunden nicht in Anspruch genommenen Zimmern hat die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) die Einnahmen
      aus anderweitiger Vermietung der Zimmer sowie die eingesparten Aufwendungen anzurechnen.</P>
    <P>4. der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) steht es frei, die vertraglich vereinbarte Vergütung zu verlangen und den Abzug für ersparte Aufwendungen zu pauschalieren. Der Kunde ist in diesem Fall verpflichtet, mindestens 90% des vertraglich vereinbarten Preises für Übernachtung mit oder ohne Frühstück.
      Dem Kunden steht der Nachweis frei, dass der oben genannte Anspruch nicht oder nicht in der geforderten Höhe entstanden ist.</P>
    <Div></Div>
<br/><br/>
    <PBold> V. Rücktritt der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH)</PBold>
    <P>1. Sofern ein kostenfreies Rücktrittsrecht des Kunden innerhalb einer bestimmten Frist schriftlich vereinbart wurde, ist die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) in diesem Zeitraum seinerseits berechtigt, vom Vertrag zurückzutreten, wenn Anfragen anderer Kunden nach den vertraglich gebuchten Zimmern vorliegen und der Kunde auf Rückfrage der V7 Berlin Residences
      (gesetzl. vertr. durch die 2inSeven GmbH) auf sein Recht zum Rücktritt nicht verzichtet.</P>
    <P>2. Wird eine vereinbarte oder oben gemäß Klausel III Nr. 6 verlangte Vorauszahlung auch nach Verstreichen einer vom Hotel gesetzten angemessenen Nachfrist nicht geleistet, so ist die V7 Berlin Residences
      (gesetzl. vertr. durch die 2inSeven GmbH) ebenfalls zum Rücktritt vom Vertrag berechtigt.</P>
    <P>3. Ferner ist die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) berechtigt,
      aus sachlich gerechtfertigtem Grund vom Vertrag außerordentlich zurückzutreten, beispielsweise falls</P>
    <ul> <li> höhere Gewalt oder andere vom Hotel nicht zu vertretende Umstände die Erfüllung
      des Vertrages unmöglich machen;</li>
    <li> Zimmer unter irreführender oder falscher Angabe wesentlicher Tatsachen,
      z.B. in der Person des Kunden oder des Zwecks, gebucht werden;</li>
      <li>die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) begründeten Anlass zu der Annahme hat, dass die Inanspruchnahme der Hotelleistung den reibungslosen Geschäftsbetrieb, die Sicherheit oder das Ansehen der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) in der Öffentlichkeit gefährden kann, ohne dass dies dem Herrschafts- bzw. Organisationsbereich
        der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) zuzurechnen ist;</li>
      <li>ein Verstoß gegen oben Klausel I Nr. 2 vorliegt.</li>
    </ul>
      <P>4. Bei berechtigtem Rücktritt der V7 Berlin Residences
        (gesetzl. vertr. durch die 2inSeven GmbH) entsteht kein Anspruch des Kunden auf Schadensersatz.</P>
      <Div></Div>
<br/> <br/>
      <PBold>VI. Zimmerbereitstellung, -übergabe und -rückgabe</PBold>
      <P> 1. Der Kunde erwirbt keinen Anspruch auf die Bereitstellung bestimmter Zimmer.</P>
      <P>2. Gebuchte Zimmer stehen dem Kunden ab 15.00 Uhr des vereinbarten Anreisetages zur Verfügung.
        Der Kunde hat keinen Anspruch auf frühere Bereitstellung.</P>
      <P>3. Am vereinbarten Abreisetag sind die Zimmer der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) spätestens um 12.00 Uhr geräumt zur Verfügung zu stellen. Danach kann die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) aufgrund der verspäteten Räumung des Zimmers für dessen vertragsüberschreitende Nutzung bis 18.00 Uhr 50% des vollen Logispreises (Listenpreises) in Rechnung stellen, ab 18.00 Uhr 100%. Vertragliche Ansprüche des Kunden werden hierdurch nicht begründet. Ihm steht es frei, nachzuweisen, dass der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH)
        kein oder ein wesentlich niedrigerer Anspruch auf Nutzungsentgelt entstanden ist.</P>
      <Div></Div>
      <br/><br/>
      <PBold>VII. Haftung der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH)</PBold>

      <P>1. die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) haftet mit der Sorgfalt eines ordentlichen Kaufmanns
        für seine Verpflichtungen aus dem Vertrag. Ansprüche des Kunden auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, wenn die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) die Pflichtverletzung zu vertreten hat, sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung der V7 Berlin Residences
        (gesetzl. vertr. durch die 2inSeven GmbH) beruhen und Schäden, die auf einer vorsätzlichen oder fahrlässigen Verletzung von vertragstypischen Pflichten der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) beruhen. Einer Pflichtverletzung der V7 Berlin Residences
        (gesetzl. vertr. durch die 2inSeven GmbH) steht die eines gesetzlichen Vertreters oder Erfüllungsgehilfen gleich. Sollten Störungen oder Mängel an den Leistungen der V7 Berlin Residences
        (gesetzl. vertr. durch die 2inSeven GmbH) auftreten, wird die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) bei Kenntnis oder auf unverzügliche Rüge des Kunden bemüht sein, für Abhilfe zu sorgen. Der Kunde ist verpflichtet,
        das ihm Zumutbare beizutragen, um die Störung zu beheben und einen möglichen Schaden gering zu halten.</P>
      <P>2. Für im Zimmer eingebrachte Sachen haftet die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) dem Kunden nach den gesetzlichen Bestimmungen, die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) empfiehlt, von der Möglichkeit Gebrauch zu machen, Wertsachen im Zimmer-Safe einzulagern. Die Haftungsansprüche erlöschen, wenn nicht der Kunde nach Erlangen der Kenntnis von Verlust, Zerstörung oder Beschädigung unverzüglich der V7 Berlin Residences
        (gesetzl. vertr. durch die 2inSeven GmbH) Anzeige macht (§ 703 BGB). Für eine weitergehende Haftung der V7 Berlin Residences
        (gesetzl. vertr. durch die 2inSeven GmbH) gelten vorstehende Nummer 1 Sätze 2 bis 4 entsprechend.</P>
      <P>3. Weckaufträge werden vom Hotel mit größter Sorgfalt ausgeführt.
        Nachrichten, Post und Warensendungen für die Gäste werden mit Sorgfalt behandelt.
        Die V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH) übernimmt die Zustellung,
        Aufbewahrung und – auf Wunsch – gegen Entgelt die Nachsendung derselben.
        Vorstehende Nummer 1 Sätze 2 bis 4 gelten entsprechend.</P>
      <Div></Div>
<br/><br/>
      <PBold>VIII. Schlussbestimmungen</PBold>
      <P>1. Änderungen oder Ergänzungen des Vertrags, der Antragsannahme oder dieser Geschäftsbedingungen für die Hotelaufnahme sollen schriftlich erfolgen.
        Einseitige Änderungen oder Ergänzungen durch den Kunden sind unwirksam.</P>
      <P> 2. Erfüllungs- und Zahlungsort ist der Sitz der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH).</P>
      <P>3. Ausschließlicher Gerichtsstand – auch für Scheck- und Wechselstreitigkeiten – ist im kaufmännischen Verkehr der Sitz der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH).
        Sofern ein Vertragspartner die Voraussetzung des § 38 Abs. 2 ZPO erfüllt und keinen allgemeinen Gerichtsstand im Inland hat,
        gilt als Gerichtsstand der Sitz der V7 Berlin Residences (gesetzl. vertr. durch die 2inSeven GmbH).</P>

      <P>4. Es gilt deutsches Recht. Die Anwendung des UN-Kaufrechts und des Kollisionsrechts ist ausgeschlossen.</P>
    <P>5. Sollten einzelne Bestimmungen dieser Allgemeinen Geschäftsbedingungen für die Hotelaufnahme unwirksam oder nichtig sein oder werden,
      so wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. Im übrigen gelten die gesetzlichen Vorschriften</P>

  </StyledDiv>

)

export default MidDiv
