import React from 'react'
import Headroom from 'react-headroom';
import NavigationDesktop from "./NavigationDesktop";

import styled from "@emotion/styled";
require('../styles/global.css')

//import { FormattedMessage } from 'react-intl';
import { V7DesktopThumb } from '../layouts/LayoutImg'


const StyledHeader = styled.div`
height:122px !important;
align-items: center;
text-align: center;

background:white;
opacity:1;

  @media screen and (max-width: 1295px) {

display:none;
   }

`

const LogoHeaderDiv = styled.div`
height:82px;
display:flex;
justify-content:center;
align-items:center;
`

interface HeaderDesktopProps {
}

const HeaderDesktop: React.FC<HeaderDesktopProps> = ({  }) => (
  <Headroom  wrapperStyle={{background: 'white', zIndex: 999, height: '115px !important'}}>
    <StyledHeader>
      <LogoHeaderDiv>
      <V7DesktopThumb/>
      </LogoHeaderDiv>
      <NavigationDesktop ></NavigationDesktop>
    </StyledHeader>
  </Headroom>
)

export default HeaderDesktop
