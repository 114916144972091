import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import { FormattedMessage } from 'react-intl';

import { StaticImage } from "gatsby-plugin-image"
const StyledLink = styled(LocalizedLink)`
opacity:1 !important;


`

import { useLightbox } from 'simple-react-lightbox'
import styled from '@emotion/styled'


import LocalizedLink from '../components/int/LocalizedLink'
// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import { useLightbox } from 'simple-react-lightbox-pro'

const imgPaths = [
 "../../../../content/booking/studio/V7_Studio_001.jpg",
]



const Div = styled.div`
`


const DesktopThumb = styled.div`


.gatsby-image-wrapper-constrained {
width:66px;
}

 @media screen and (max-width: 666px) {


}

}

`

const MobileThumb = styled.div`


.gatsby-image-wrapper-constrained {
width:66px;
}

 @media screen and (max-width: 666px) {


}

}

`





export function V7DesktopThumb() {

  return (
    <DesktopThumb>
      <StyledLink>
      <V7Img/>
      </StyledLink>
    </DesktopThumb>
  )
}

export function V7MobileThumb() {

  return (
    <MobileThumb>
      <V7Img/>
    </MobileThumb>
  )
}


export function V7Img() {
  return (
    <div>
      <StaticImage
        src="../content/layout/V7_000_Logo.png"
        alt={"V7 Logo"}
        placeholder="none"
        layout="constrained"
        quality="100"


      />

    </div>
  )
}

export function Vilhelm7Img() {
  return (
    <div>
      <StaticImage
        src="../content/layout/V7_000_Logo.png"
        alt={"V7 Logo"}
        placeholder="none"
        layout="constrained"
        quality="100"


      />

    </div>
  )
}


export function Vilhelm7MobileImg() {
  return (
    <div>
      <StaticImage
        src="../content/layout/V7_000_Logo.png"
        alt={"V7 Logo"}
        placeholder="none"
        layout="constrained"
        quality="100"


      />

    </div>
  )
}
